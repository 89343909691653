export default {
  methods: {
    openMailTo () {
      window.open('mailto:info@pigenta.com', '_blank')
    },
    openContactForm () {
      window.location.href='https://www.pigenta.com/#kontakt'
    },
  },
}
