<template>
  <md-table-row
    class="admin__table-row admin__table-row-details"
    :key="`finalizedDocument-${finalizedDocument.id}`"
    :class="{ 'finalized-document__row--selected' : selectedFinalizedDocument && selectedFinalizedDocument.id === finalizedDocument.id}"
  >
    <finalized-document-column
      v-for="column in textFinalizedDocumentColumns"
      :key="`${finalizedDocument.id}-${column.id}`"
      :finalized-documents="[finalizedDocument]"
      :column="column"
      @status-click="onStatusClick"
    />
    <custom-attribute-column
      v-for="column in customAttributeColumns"
      :key="`${finalizedDocument.id}-${column.id}`"
      :finalized-document="finalizedDocument"
      :column="column"
    />
  </md-table-row>
</template>

<script>
import { mapState } from 'vuex'
import FinalizedDocumentColumn from './FinalizedDocumentColumn'
import CustomAttributeColumn from './CustomAttributeColumn'
import FinalizedDocumentDisplayMixin from '../../shared/FinalizedDocumentDisplayMixin'

export default {
  components: {
    FinalizedDocumentColumn,
    CustomAttributeColumn,
  },
  props: {
    finalizedDocument: { type: Object, required: true },
    customAttributeColumns: { type: Array, required: true },
    textFinalizedDocumentColumns: { type: Array, required: true },
  },
  mixins: [FinalizedDocumentDisplayMixin],
  computed: {
    ...mapState(['account']),
    ...mapState('accountStore/finalizedDocuments', ['selectedFinalizedDocument']),
    envelopeColumns () {
      return this.textFinalizedDocumentColumns.filter(column => column.id !== 'name')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';
@import '@/components/common';
@import '@/components/admin_common';
@import './finalized_document_display';
</style>
