import axios from 'axios'
import { camelKeys } from 'js-convert-case'
import router from '@/router'
import { event, set } from 'vue-gtag'
import { isAnalyticsAllowed } from '../../../utils'

const notificationPingInterval = 60e3 // 1min
const MESSAGE_DURATION = 3000
const PERSONAL_ACCOUNT_ID = 'osebno'

const state = {
  user: null,
  account: null,
  isMobile: false,
  isFloatingSidebarVisible: false,
  isAuthModalOpen: false,
  canAuthModalBeClosed: true,
  authModalBlur: true,
  authModalTab: 'login',
  message: {
    text: null,
    type: null,
    duration: Infinity,
  },
  messageTimeout: null,
  isLoading: false,
  unreadNotificationsCount: 0,
  getUnreadNotificationsCountInterval: null,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    personalAccountId () {
      return PERSONAL_ACCOUNT_ID
    },
    accountId (state) {
      if (!state.account) {
        return null
      }

      return state.account.id
    },
    accountSlug (state, getters) {
      if (!state.account) {
        return getters.personalAccountId
      }

      return state.account.slug
    },
    accounts (state) {
      if (!state.user) {
        return []
      }

      return state.user.accounts.sort((a, b) => a.name.localeCompare(b.name))
    },
    getAccountIdForPath: (_, getters) => (accountId) => {
      if (accountId) {
        return accountId
      }
      return getters.personalAccountId
    },
    getAccountIdFromSlug: (_, getters) => (slug) => {
      if (slug === getters.personalAccountId) {
        return 'null'
      }

      return slug
    },
    isLoggedIn (state) {
      return state.user !== null
    },
    isSidebarPresent (state, getters) {
      return getters.isLoggedIn && !state.isMobile
    },
    isFloatingSidebarVisible (state) {
      return state.isFloatingSidebarVisible || !state.isMobile
    },
    isAdmin (state) {
      return state.user && state.user.isAdmin
    },
    isAccountMember (state) {
      return state.account != null
    },
    isAccountAdmin (state) {
      if (!state.account || !state.user) {
        return false
      }

      const currentMembership = state.user.accountMemberships.find(am => am.accountId === state.account.id)
      if (!currentMembership) {
        return false
      }

      return currentMembership.isAccountAdmin
    },
  },
  actions: {
    setUser ({ commit, dispatch }, user) {
      commit('SET_USER', user)
      dispatch('setUnreadNotificationsCountInterval')
      dispatch('claimDocuments')
      if (user) {
        dispatch('setForTracking', { key: 'email', value: user.email })
      }
    },
    setAccount ({ commit }, account) {
      // Redirect to user profile page, if we're on an account-admin-only page
      if (!account && router.currentRoute.meta.isAccountAdminRequired) {
        router.push({ name: 'account_recent_finalized_documents_path' })
      }

      commit('SET_ACCOUNT', account)
      if (account && account.id) {
        localStorage.setItem('defaultAccountId', account.id) // TODO: when we start using local storage more often, create a composable
      }
    },
    setUnreadNotificationsCountInterval ({ commit, dispatch, state }) {
      if (state.getUnreadNotificationsCountInterval) {
        clearInterval(state.getUnreadNotificationsCount)
        commit('SET_GET_UNREAD_NOTIFICATIONS_COUNT_INTERVAL', null)
      }

      if (state.user && state.account) {
        dispatch('getUnreadNotificationsCount')
        const getUnreadNotificationsCountInterval = setInterval(() => {
          dispatch('getUnreadNotificationsCount')
        }, notificationPingInterval)
        commit('SET_GET_UNREAD_NOTIFICATIONS_COUNT_INTERVAL', getUnreadNotificationsCountInterval)
      }
    },
    getUnreadNotificationsCount ({ commit }) {
      axios.get('/notifications/mine?by_is_read=0&is_peek_request=1&limit=1')
        .then(response => commit('SET_UNREAD_NOTIFICATIONS_COUNT', parseInt(response.headers['total-count'])))
        .catch(error => console.error(error))
    },
    setIsMobile ({ commit }) {
      const isMobile = window.innerWidth < 800 || (navigator.userAgentData && navigator.userAgentData.mobile)
      commit('SET_IS_MOBILE', isMobile)
    },
    setIsFloatingSidebarVisible ({ commit }, isFloatingSidebarVisible) {
      commit('SET_IS_FLOATING_SIDEBAR_VISIBLE', isFloatingSidebarVisible)
    },
    toggleIsFloatingSidebarVisible ({ commit, state }) {
      commit('SET_IS_FLOATING_SIDEBAR_VISIBLE', !state.isFloatingSidebarVisible)
    },
    openAuthModal ({ commit }, options = {}) {
      const allowModalClose = options.allowModalClose ?? true
      const modalBlur = options.modalBlur ?? true
      commit('SET_IS_AUTH_MODAL_OPEN', true)
      commit('SET_CAN_AUTH_MODAL_BE_CLOSED', !!allowModalClose)
      commit('SET_AUTH_MODAL_BLUR', !!modalBlur)
    },
    closeAuthModal ({ commit }) {
      commit('SET_IS_AUTH_MODAL_OPEN', false)
    },
    setAuthModalTab ({ commit }, tab) {
      commit('SET_AUTH_MODAL_TAB', tab)
    },
    uploadFile (_, { file, prefix }) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('prefix', prefix)

      return axios.post('/uploads', formData)
    },
    clearMessage ({ commit }) {
      commit('SET_MESSAGE', null)
    },
    setMessage ({ commit }, message) {
      commit('SET_MESSAGE', message)
    },
    subscribeToNewsletter ({ commit, dispatch }, { email, useSecondaryMailingList }) {
      commit('SET_IS_LOADING', true)
      dispatch('clearMessage')
      return axios.post('/brevo/contacts', { email, use_secondary_list: useSecondaryMailingList })
        .then(response => {
          if (response.data && response.data.message) {
            return response.data.message
          }
        })
        .catch(error => {
          commit('SET_MESSAGE', { text: 'Napaka pri prijavljanju na novice, prosimo poskusite kasneje.', type: 'error' })
          throw error
        })
        .finally(() => commit('SET_IS_LOADING', false))
    },
    async claimDocuments ({ rootState }) {
      if (!rootState.user) {
        return
      }

      const unclaimedDocumentIds = JSON.parse(localStorage.getItem('unclaimedDocumentIds'))
      if (!unclaimedDocumentIds || unclaimedDocumentIds.length === 0) {
        return
      }

      const promises = unclaimedDocumentIds.map(documentId => {
        return new Promise(resolve => {
          axios
            .get(`/finalized_documents/${documentId}`)
            .then(response => {
              const envelopeId = response.data.envelope_id
              axios
                .put(`/envelopes/${envelopeId}`, { envelope: { user_id: rootState.user.id } })
                .then(() => resolve())
                .catch(error => console.error(error))
            })
            .catch(error => console.error(error))
        })
      })
      await Promise.all(promises)
      localStorage.removeItem('unclaimedDocumentIds')
      const lastDocumentId = unclaimedDocumentIds[unclaimedDocumentIds.length - 1]
      router.push({ name: 'finalized_document_payment', params: { id: lastDocumentId } }).catch(() => {})
    },
    setForTracking (_, { key, value }) {
      if (!isAnalyticsAllowed()) {
        return
      }
      set(key, value)
    },
    trackEvents (_, events) {
      if (!isAnalyticsAllowed()) {
        return
      }
      events.forEach(eventData => {
        event(eventData.name, {
          ...eventData.data,
          user_id: this.user?.id,
        })
      })
    },
  },
  mutations: {
    SET_USER (state, user) {
      state.user = camelKeys(user, { recursive: true, recursiveInArray: true })
    },
    SET_ACCOUNT (state, account) {
      state.account = camelKeys(account, { recursive: true, recursiveInArray: true })
    },
    SET_UNREAD_NOTIFICATIONS_COUNT (state, unreadNotificationsCount) {
      state.unreadNotificationsCount = unreadNotificationsCount
    },
    SET_GET_UNREAD_NOTIFICATIONS_COUNT_INTERVAL (state, getUnreadNotificationsCountInterval) {
      state.getUnreadNotificationsCountInterval = getUnreadNotificationsCountInterval
    },
    SET_IS_MOBILE (state, isMobile) {
      state.isMobile = isMobile
    },
    SET_IS_FLOATING_SIDEBAR_VISIBLE (state, isFloatingSidebarVisible) {
      state.isFloatingSidebarVisible = isFloatingSidebarVisible
    },
    SET_IS_AUTH_MODAL_OPEN (state, isAuthModalOpen) {
      state.isAuthModalOpen = isAuthModalOpen
      if (!isAuthModalOpen) {
        state.authModalTab = 'login'
      }
    },
    SET_CAN_AUTH_MODAL_BE_CLOSED (state, canAuthModalBeClosed) {
      state.canAuthModalBeClosed = canAuthModalBeClosed
    },
    SET_AUTH_MODAL_BLUR (state, authModalBlur) {
      state.authModalBlur = authModalBlur
    },
    SET_AUTH_MODAL_TAB (state, tab) {
      state.authModalTab = tab
    },
    SET_IS_LOADING (state, isLoading) {
      state.isLoading = isLoading
    },
    SET_MESSAGE (state, message) {
      if (!message) {
        state.message = {
          text: null,
          type: null,
          duration: Infinity,
        }
        return
      }
      if (state.messageTimeout) {
        clearTimeout(state.messageTimeout)
        state.messageTimeout = null
      }
      state.message = {
        text: message.text,
        type: message.type || 'info',
      }
      if (message.duration) {
        state.message.duration = message.duration
      } else if (message.type === 'error') {
        state.message.duration = Infinity
      } else {
        state.message.duration = MESSAGE_DURATION
      }

      if (state.message.duration !== Infinity) {
        const timeoutId = setTimeout(() => {
          state.message = null
        }, state.message.duration)
        state.messageTimeout = timeoutId
      }
    },
  },
}
