<template>
  <div class="page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="checkout__heading">
      <router-link :to="{ name: 'account_recent_finalized_documents_path', accountId: accountSlug }"><icon class="checkout__heading-button" name="arrow-left" /></router-link>Košarica
    </div>
    <div class="checkout__loading" v-if="isLoading">
      <dialog-spinner />
    </div>
    <div class="checkout" v-if="!isLoading && finalizedDocument">
      <div class="checkout__column">
        <div class="checkout__document">
          <div><icon name="document" />Dokument</div>
          <div class="checkout__document-name">{{ finalizedDocument.name }}</div>
        </div>
        <div class="checkout__promotion-code">
          <div class="checkout__promotion-code-heading">Ali imaš kupon?</div>
          <v-field>
            <label>Koda kupona</label>
            <v-input v-model="promotionCode" @keydown.enter="verifyPromotionCode" ref="promotionCodeInput" :disabled="isPriceReduced" />
          </v-field>
          <v-button @click="togglePromotionCode" size="medium">{{ paymentButtonText }}</v-button>
        </div>
      </div>
      <div class="checkout__column">
        <div class="checkout__payment-wrapper">
          <div class="checkout__price" v-if="finalizedDocument">
            <div class="checkout__price-item">
              <span>Znesek:</span><span>{{ originalPrice }} €</span>
            </div>
            <div class="checkout__vat">
              22% DDV je vštet v ceno ({{ vatAmount }} €)
            </div>
            <div class="checkout__promotion-success" v-if="isPriceReduced">
              <span>Koda {{ promotionCode }}</span><span>{{ reducedPriceDifference }} €</span>
            </div>
            <div class="checkout__price-total"><span>Skupaj:</span><span>{{ finalizedDocumentPrice }} €</span></div>
          </div>

          <div class="checkout__wrapper checkout__stripe">
            <div
              id="stripe-button-loader"
              class="checkout__button-loader checkout__button-loader--stripe"
              ref="stripeButtonLoader"
            >
              <dialog-spinner />
            </div>
            <div class="checkout__payment-button checkout__button checkout__button--stripe" @click="initiateStripeCheckout" ref="stripeButton">
              Plačilo s kreditno kartico
            </div>
            <payment-options class="checkout__payment-options" />
          </div>

          <hr class="checkout__separator" />

          <div class="checkout__wrapper checkout__paypal">
            <div class="checkout__payment-button">
              <div id="paypal-button-loader" class="checkout__button-loader" ref="payPalButtonLoader">
                <dialog-spinner />
              </div>
              <div id="paypal-button" class="checkout__button checkout__button--paypal" ref="payPalButton" @click="trackPaymentEvents('PayPal')"></div>
            </div>
          </div>
        </div>

        <div class="checkout__payment-wrapper">
          <payment-guarantee />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DialogSpinner from '../shared/_DialogSpinner'
import VButton from 'design-system/VButton.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'
import Icon from 'design-system/icons/Icon.vue'
import PaymentGuarantee from './PaymentGuarantee.vue'
import PaymentOptions from '../shared/_PaymentOptions.vue'

export default {
  components: {
    DialogSpinner,
    VButton,
    VField,
    VInput,
    Icon,
    PaymentGuarantee,
    PaymentOptions,
  },
  props: {
    id: { type: [String, Number], required: true },
  },
  data () {
    return {
      scriptElements: [],
      promotionCode: null,
    }
  },
  created () {
    // inject a stripe & braintree script elements when the Checkout component is mounted
    this.insertStripeScriptTags()
    this.insertBraintreeScriptTags()

    if (!this.user) {
      this.openAuthModal({
        allowModalClose: false,
      })
    }
  },
  mounted () {
    this.initialize(this.id).then(() => this.initializePayPalButton())
  },
  beforeDestroy () {
    this.removeScriptTags()
    this.reset()
  },
  computed: {
    ...mapState(['isLoading', 'user']),
    ...mapState('payments', ['finalizedDocument', 'braintreeClientToken']),
    ...mapGetters(['accountSlug']),
    ...mapGetters('payments', ['finalizedDocumentPrice', 'originalPrice', 'isPriceReduced', 'vatAmount', 'reducedPriceDifference']),
    paymentButtonText () {
      return this.isPriceReduced ? 'Odstrani' : 'Vnesi'
    },
    userId () {
      return this.user?.id
    },
  },
  methods: {
    ...mapActions(['openAuthModal']),
    ...mapActions('payments', ['initialize', 'initializeBraintree', 'setPromotionCode', 'stripeCheckout', 'trackPaymentEvents', 'reset']),
    insertStripeScriptTags () {
      this.insertScriptTags(['https://js.stripe.com/v3/'])
    },
    insertBraintreeScriptTags () {
      this.insertScriptTags([
        'https://js.braintreegateway.com/web/3.71.1/js/client.min.js',
        'https://js.braintreegateway.com/web/3.71.1/js/paypal-checkout.min.js',
        'https://js.braintreegateway.com/web/3.71.1/js/data-collector.min.js',
      ])
    },
    insertScriptTags (scriptSrcs) {
      scriptSrcs.forEach((scriptSrc) => {
        const scriptElement = document.createElement('script')
        scriptElement.setAttribute('src', scriptSrc)
        document.head.appendChild(scriptElement)
        this.scriptElements.push(scriptElement)
      })
    },
    removeScriptTags () {
      this.scriptElements.forEach((scriptElement) => document.head.removeChild(scriptElement))
    },
    initiateStripeCheckout () {
      this.trackPaymentEvents('Credit card')
      this.$refs.stripeButtonLoader.style.display = 'flex'
      this.$refs.stripeButton.style.display = 'none'

      this.stripeCheckout()
    },
    initializePayPalButton () {
      if (!this.braintreeClientToken || typeof braintree === 'undefined') {
        setTimeout(this.initializePayPalButton, 500)
        return
      }

      this.initializeBraintree('#paypal-button')
      this.stylePayPalButton()
    },
    stylePayPalButton () {
      const paypalButtonLoader = this.$refs.payPalButtonLoader
      if (!paypalButtonLoader) {
        console.error('PayPal button loader and/or PayPal button not found!')
        setTimeout(this.stylePayPalButton, 500)
        return
      }

      paypalButtonLoader.style.display = 'none'
      if (this.$refs.payPalButton) {
        this.$refs.payPalButton.style.display = 'block'
      }
    },
    togglePromotionCode () {
      if (this.isPriceReduced) {
        this.setPromotionCode(null)
        this.promotionCode = null
        this.$nextTick(() => this.$refs.promotionCodeInput.$el.focus())
      } else {
        this.verifyPromotionCode()
      }
    },
    verifyPromotionCode () {
      if (!this.promotionCode) {
        return
      }

      this.setPromotionCode(this.promotionCode)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./common";
@import "../common";
@import 'app/assets/stylesheets/colors';

.checkout__loading {
  display: flex;
  justify-content: center;
  margin-top: 64px;

  .checkout__spinner {
    width: 48px;
    height: 48px;
  }
}

.checkout {
  display: flex;

  @include respond-to(small) {
    display: block;
    padding-top: 0;
  }

  & .checkout__column {
    flex: 1;

    @include respond-to(small) {
      margin-top: 56px;
    }
  }
}

.checkout__heading-button {
  cursor: pointer;
}

.checkout__payment-button {
  max-width: 400px;
  margin: auto;
}

.checkout__wrapper {
  padding: 0 32px;
  margin-bottom: 16px;
}

.checkout__emphasized-text {
  font-weight: 800;
  line-height: 16px;
  text-align: center;
}

.checkout__button--stripe {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: $secondary_color;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #006CA8;
  }

  @include respond-to(small) {
    height: 35px;
    line-height: 11px;
  }
}

.checkout__button--paypal {
  display: none;
}

.checkout__button-loader {
  display: flex;
  justify-content: center;
  padding-top: 7px;

  &--stripe {
    display: none;
  }
}

.checkout__column {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
    margin-left: 20px;

    @include respond-to(small) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  @include respond-to(small) {
    margin-right: 0;
    margin-left: 0;
  }
}

.checkout__document {
  padding: 32px 32px 48px;
  border: 1px solid $input-light;
  border-radius: 8px;
  margin-bottom: 48px;
  background:$white;

  & .icon-wrapper {
    display: inline;
    margin-right: 6px;
    color: #728587
  }

  & .checkout__document-name {
    margin-top: 24px;
  }
}

.checkout__price-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 16px;
}

.checkout__price-total {
  display: flex;
  justify-content: space-between;
  margin: 16px 0 26px;
  font-size: 18px;
}

.checkout__vat {
  margin: 16px 0;
  color: #A8A8A8;
  font-size: 14px;
}

.checkout__payment-wrapper {
  margin-bottom: 16px;
  border: 1px solid $input-light;
  background: $white;

  & hr {
   border: 1px solid $input-light;
  }
}

.checkout__price {
  padding: 32px 32px 0;
}

.checkout__paypal {
  padding: 0 32px;
}

.checkout__promotion-success {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 19px;
  margin: 16px -16px;
  background-color: #E7F6EA;
  color: $green;
  font-size: 15px;
  font-weight: 600;
}

.checkout__document,
.checkout__promotion-code .checkout__promotion-code-heading,
.checkout__price .checkout__price-item,
.checkout__price .checkout__price-total {
  font-weight: 600;
}

.checkout__payment-options {
  margin: 24px 0 30px;

  & /deep/ .payment-option:not(:last-child) {
    margin-right: 50px;

    @include respond-to(not-large) {
      margin-right: 20px;
    }
  }
}

.checkout__separator {
  margin: 24px;
}
</style>
