import Auth from '@/components/auth/Auth.vue'
import LoginPage from '@/components/auth/LoginPage.vue'
import Registered from '@/components/auth/Registered.vue'
import ConfirmationResend from '@/components/auth/ConfirmationResend.vue'
import PasswordResetInitiate from '@/components/auth/PasswordResetInitiate.vue'
import PasswordReset from '@/components/auth/PasswordReset.vue'
import PasswordResetSent from '@/components/auth/PasswordResetSent.vue'

const authRoutes = [
  {
    path: '/avtentikacija',
    component: Auth,
    name: 'auth_path',
    meta: { title: 'Avtentikacija' },
    children: [
      { path: '/registriran', component: Registered, name: 'registered_path', meta: { title: 'Potrditveno sporočilo za registracijo računa je poslano', description: 'Na vaš e-poštni naslov smo poslali sporočilo za potrditev registracije vašega Pigenta računa.' } },
      { path: '/ponovno-poslji-potrditveni-email', component: ConfirmationResend, name: 'confirmation_resend_path', meta: { title: 'Ponovno pošiljite potrditveno sporočilo', description: 'Ponovno pošljite potrditveno sporočilo na svoj e-poštni naslov.' } },
      { path: '/ponastavi-geslo', component: PasswordResetInitiate, name: 'password_reset_path', meta: { title: 'Ponastavite pozabljeno geslo ', description: 'Če ste pozabili geslo svojega Pigenta računa, ga lahko ponastavite tukaj.' } },
      { path: '/ponastavitev-gesla', component: PasswordReset, name: 'password_reset_form_path', meta: { title: 'Nastavite novo geslo', description: 'Nastavite novo geslo svojega Pigenta računa.' }, props: route => ({ token: route.query.token }) },
      { path: '/ponastavitev-gesla-poslana', component: PasswordResetSent, name: 'password_reset_sent_path', meta: { title: 'Navodila za ponastavitev gesla so poslana', description: 'V vašem e-poštnem nabiralniku vas čakajo navodila za zaključek nastavitve novega gesla vašega Pigenta računa.' } },
    ].map(route => {
      return {
        ...route,
        meta: { ...route.meta, isHeaderHidden: true },
      }
    }),
  },
  { path: '/prijava', component: LoginPage, name: 'login_path', meta: { isHeaderHidden: true, title: 'Prijava', description: 'Prijavite se v aplikacijo Pigenta in dostopajte do svojih pogodb ter pravnih dokumentov.' } },
  { path: '/users/confirmation', component: LoginPage, name: 'already_confirmed_path', meta: { isHeaderHidden: true, title: 'Prijava', description: 'Prijavite se v aplikacijo Pigenta in dostopajte do svojih pogodb ter pravnih dokumentov.' } }, // If the user is already confirmed, devise will redirect here
]

export default authRoutes.map(route => {
  route.meta.isLoginRequired = false
  route.meta.isAuthPath = true

  if (route.children) {
    route.children.forEach(childRoute => {
      childRoute.meta.isLoginRequired = false
      childRoute.meta.isAuthPath = true
    })
  }

  return route
})
