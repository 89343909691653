import axios from 'axios'
import snarkdown from 'snarkdown'
import { orderBy, uniqBy, uniq, escape, cloneDeep } from 'lodash-es'
import { camelKeys, snakeKeys } from 'js-convert-case'
import { getResponseErrorMessage } from '@/store/utils'
import router from '@/router'
import {
  getNearestMultiSelectConditionWithOutcomeIndexForTextSegment,
  getNearestMultiSelectConditionWithOutcomeIndexForCondition,
  getGlobalOrder,
  isSmartVariableContainingName,
} from './utils'

const color = '#FFFFFF'
const backgroundColor = '#98BAD8'
const wrapperHTMLTags = `style="color: ${color}; background-color: ${backgroundColor}; width: fit-content;"`

const state = {
  document: {},
  chosenConditionOutcomes: [],
  variableInputs: [],
  finalizedDocumentId: null,
  isDocumentSaved: false,
  isEditingRestricted: true,
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    document (state, _, rootState) {
      if (!state.document) {
        return null
      }

      return {
        ...state.document,
        priceLabel: rootState.account ? 'Brezplačno' : `${state.document.price} €`,
      }
    },
    hasUnsavedChanges (state) {
      const hasUserInput = state.variableInputs.length > 0 || state.chosenConditionOutcomes.length > 0
      return hasUserInput && !state.isDocumentSaved
    },
    textSegmentsToRender (state, getters) {
      let textSegmentsToRender = getters.visibleTextSegments
      // always show some text to the user, even if all segments are under a condition
      if (getters.visibleTextSegmentsWithContent.length === 0) {
        textSegmentsToRender = orderBy(state.document.textSegments, 'order').slice(0, 3)
      }

      return textSegmentsToRender
    },
    visibleTextSegments (state) {
      if (!state.document.textSegments) {
        return []
      }

      const textSegments = state.document.textSegments
        .filter(textSegment => textSegment.conditionOutcomeId == null || state.chosenConditionOutcomes.includes(textSegment.conditionOutcomeId))
      return orderBy(textSegments, 'order')
    },
    visibleTextSegmentsWithContent (state, getters) {
      return getters.visibleTextSegments.filter(segment => segment.textContent.text.trim() !== '')
    },
    visibleVariables (state, getters) {
      const duplicableTextSegmentIds = []
      const removableTextSegmentIds = []
      let visibleVariables = []
      getters.visibleTextSegments.forEach(textSegment => {
        // variables should inherit the global order from the text segment since variables themselves only have a local order in scope of a text content
        // if variables are nested under a multiselect condition outcome, they should be positioned after that outcome
        const variablesWithGlobalOrder = textSegment.textContent.variables
          .slice() // make a copy
          .reverse() // reverse so that isDuplicable is only set to true on the last variable of textContent
          .map(variable => {
            const { multiSelectCondition, multiSelectConditionOutcomeIndex } = getNearestMultiSelectConditionWithOutcomeIndexForTextSegment(textSegment, state.document.conditions)
            const globalOrder = getGlobalOrder(textSegment, multiSelectCondition, multiSelectConditionOutcomeIndex)

            let isDuplicable = false
            if (textSegment.isDuplicable && !duplicableTextSegmentIds.includes(textSegment.id) && !duplicableTextSegmentIds.includes(textSegment.originalId)) {
              isDuplicable = true
              duplicableTextSegmentIds.push(textSegment.id)
            }

            let isRemovable = false
            if (textSegment.isDuplicate && !removableTextSegmentIds.includes(textSegment.id)) {
              isRemovable = true
              removableTextSegmentIds.push(textSegment.id)
            }

            return {
              ...variable,
              globalOrder: globalOrder,
              title: textSegment.sectionName,
              textSegmentId: textSegment.id,
              isNested: globalOrder % 1 !== 0, // nested variables will have float order
              isDuplicable,
              isRemovable,
            }
          })
        visibleVariables = visibleVariables.concat(variablesWithGlobalOrder.reverse())
      })

      // variable title should only be visible on the first variable of the segment
      const titledTextSegmentIds = []
      visibleVariables.forEach(variable => {
        if (!variable.title) {
          return
        }

        if (titledTextSegmentIds.includes(variable.textSegmentId)) {
          variable.title = null
        } else {
          titledTextSegmentIds.push(variable.textSegmentId)
        }
      })

      // only ask the user for input once per variable pattern
      return uniqBy(visibleVariables, (variable) => variable.pattern)
    },
    visibleConditions (state) {
      if (!state.document.conditions) {
        return []
      }

      const conditions = state.document.conditions.filter(condition => condition.conditionOutcomeId == null || state.chosenConditionOutcomes.includes(condition.conditionOutcomeId))
      return conditions.map(condition => {
        // conditions should set the globalOrder property to the same value as order as their order is global to the document already
        return {
          ...condition,
          globalOrder: condition.order,
        }
      })
    },
    requiredConditions (_, getters) {
      return getters.visibleConditions.filter(condition => !condition.isMultiSelect)
    },
    effectiveVariableInputs (state, getters) {
      const defaultInputs = []
      const variablesWithDefaultValue = getters.visibleVariables.filter(variable => variable.defaultValue)
      variablesWithDefaultValue.forEach(variable => {
        const variableInputIds = state.variableInputs.map(input => input.id)
        if (!variableInputIds.includes(variable.id)) {
          defaultInputs.push({
            id: variable.id,
            value: variable.defaultValue,
            pattern: variable.pattern,
          })
        }
      })

      const visibleVariableIds = getters.visibleVariables.map(variable => variable.id)
      const visibleVariableInputs = state.variableInputs.filter(variableInput => visibleVariableIds.includes(variableInput.id))
      return visibleVariableInputs.concat(defaultInputs)
    },
    interpolatedDocumentMarkdown (state, getters) {
      return function (isFinalMarkdown = false) {
        if (!state.document) {
          return ''
        }

        let text = isFinalMarkdown ? `# ${state.document.name}` : `## <div align="center">${state.document.name}</div><br/>\n`
        let numbering = null

        getters.textSegmentsToRender.forEach(segment => {
          if (segment.textContent.text.trim() === '') {
            return
          }

          const interpolatedContent = interpolateTextContent(segment.textContent, isFinalMarkdown)
          // segment should not be numbered if isNumberedSegment is false, quick return with content
          if (segment.isNumberedSegment === false) {
            text += getFinalizedHtml(interpolatedContent, segment.conditionOutcomeId, isFinalMarkdown)
            return
          }

          const isMultiSelectSegment = getNearestMultiSelectConditionWithOutcomeIndexForTextSegment(segment, state.document.conditions).multiSelectCondition !== null
          // for segments that need per-paragraph numbering we need to break them down, insert numbering and combine them back
          if (segment.isSegmentContentNumbered) {
            let paragraphText = ''
            getParagraphs(interpolatedContent).forEach(paragraph => {
              let segmentContent = paragraph.innerHTML
              // don't number empty paragraphs
              if (segmentContent.trim().length > 0) {
                numbering = updateNumbering(numbering, segment.isRootSegment, isMultiSelectSegment)
                segmentContent = insertNumbering(numbering.text, segmentContent)
                paragraphText += getFinalizedHtml(segmentContent, segment.conditionOutcomeId, isFinalMarkdown, false)
              } else {
                paragraphText += getFinalizedHtml(segmentContent, segment.conditionOutcomeId, isFinalMarkdown)
              }
            })
            text += wrapIntoParagraphs(paragraphText)
          } else {
            numbering = updateNumbering(numbering, segment.isRootSegment, isMultiSelectSegment)
            const numberedContent = insertNumbering(numbering.text, interpolatedContent)
            text += getFinalizedHtml(numberedContent, segment.conditionOutcomeId, isFinalMarkdown)
          }

        })

        return text
      }

      function getFinalizedHtml (text, conditionOutcomeId, isFinalMarkdown, shouldWrapIntoParagraphs = true) {
        let html = text
        if (shouldWrapIntoParagraphs) {
          // add newlines before and after paragraph to allow markdown to evaluate text
          // in the paragraph correctly even if it starts with control chars (bullets, etc.)
          html = wrapIntoParagraphs(text)
        }

        const isConditional = conditionOutcomeId != null
        const shouldAddBackgroundColorAndAnchor = isConditional && !isFinalMarkdown
        if (shouldAddBackgroundColorAndAnchor) {
          const outcomeDomId = getters.getOutcomeDOMIdFn(conditionOutcomeId)
          html = addBackgroundColorAndAnchor(html, outcomeDomId)
        }

        return html
      }

      function wrapIntoParagraphs (html) {
        if (html.trim().length > 0) {
          return `<p>\n${html}\n</p>\n`
        } else {
          return `<p>${html}</p>`
        }
      }

      function addBackgroundColorAndAnchor (html, outcomeDomId) {
        return `<div id="${escape(outcomeDomId)}" class="anchor" ${wrapperHTMLTags}>${html}</div>`
      }

      function interpolateTextContent (textContent, isFinalMarkdown) {
        let text = textContent.text.trim()
        textContent.variables.forEach(variable => {
          const variableInput = getters.effectiveVariableInputs.find(input => input.id === variable.id || input.pattern === variable.pattern)
          if (variableInput) {
            const variableDomId = getters.getVariableDOMIdFn(variable.id)
            const prefix = isFinalMarkdown ? '' : `<span id="${escape(variableDomId)}" class="anchor" style="position: absolute; visibility: hidden; pointer-events: none;"></span>`
            const variablePart = isFinalMarkdown ? variableInput.value : `<span ${wrapperHTMLTags}>${escape(variableInput.value)}</span>`
            text = text.replaceAll(variable.pattern, `${prefix}${variablePart}`)
          } else {
            text = text.replaceAll(variable.pattern, `<span id="variable-${variable.id}" style="color: ${color}; background-color: ${backgroundColor}; padding: 2px 6px; border-radius: 4px;" custom-style="highlighted">${escape(variable.name)}</span>`)
          }
        })

        return text
      }

      function getParagraphs (html) {
        // we use a fake html document to do the paragraph parsing correctly
        const el = document.createElement('html')
        el.innerHTML = html
        return [...el.getElementsByTagName('p')]
      }

      function updateNumbering (numbering, isRootSegment, isMultiSelectSegment) {
        if (numbering) {
          numbering = JSON.parse(JSON.stringify(numbering))
        } else {
          numbering = {
            text: '',
            numberSequence: '',
            rootSegmentNumber: 0,
            segmentNumber: 0,
            multiSelectSegmentNumber: 0,
            wasPreviousSegmentMultiSelect: false,
          }
        }

        // if multi select was on 2nd level, we need to set segmentNumber to the next number of multiSelectSegmentNumber
        if (numbering.wasPreviousSegmentMultiSelect && !isMultiSelectSegment && numbering.segmentNumber === 0) {
          numbering.segmentNumber = numbering.multiSelectSegmentNumber++
        }

        if (isRootSegment) {
          numbering.segmentNumber = 0
          numbering.multiSelectSegmentNumber = 0
          numbering.rootSegmentNumber = numbering.rootSegmentNumber + 1
          numbering.numberSequence = `${numbering.rootSegmentNumber}.`
        } else {
          if (numbering.rootSegmentNumber === 0) {
            numbering.rootSegmentNumber = 1
          }

          if (isMultiSelectSegment) {
            numbering.multiSelectSegmentNumber++
            numbering.numberSequence = numbering.segmentNumber === 0 ? `${numbering.rootSegmentNumber}.${numbering.multiSelectSegmentNumber}.` : `${numbering.rootSegmentNumber}.${numbering.segmentNumber}.${numbering.multiSelectSegmentNumber}.`
          } else {
            numbering.segmentNumber++
            numbering.numberSequence = `${numbering.rootSegmentNumber}.${numbering.segmentNumber}.`
          }
        }

        numbering.text = `&#8291;${numbering.numberSequence}`

        if (numbering.wasPreviousSegmentMultiSelect && !isMultiSelectSegment) {
          // we've reached the end of multi select segment, let's reset the appropriate counters
          numbering.multiSelectSegmentNumber = 0
        }
        // mark if this segment was multi select
        numbering.wasPreviousSegmentMultiSelect = isMultiSelectSegment

        return numbering
      }

      function insertNumbering (numberingText, text) {
        // numbering has to be inserted after the leading hashes if they are present
        const regExp = /^([#])+/
        const headingHashesMatch = text.match(regExp)
        const headingHashes = headingHashesMatch ? `${headingHashesMatch[0]} ` : ''
        let textWithoutHeadingHashes = text.replace(regExp, '')
        if (!textWithoutHeadingHashes.startsWith(' ')) {
          textWithoutHeadingHashes = ` ${textWithoutHeadingHashes}`
        }

        return `${headingHashes}${numberingText}${textWithoutHeadingHashes}`
      }
    },
    interpolatedDocumentHtmlFn (_, getters) {
      return function (isFinalMarkdown) {
        if (!getters.interpolatedDocumentMarkdown(false)) {
          return ''
        }
        const html = snarkdown(getters.interpolatedDocumentMarkdown(isFinalMarkdown))
        return html.replaceAll(/&#8291;/g, '')
      }
    },
    visibleConditionConfigurationObjects (state, getters) {
      // multi select conditions are prepared as separate
      // configuration objects condition outcomes per-condition outcome
      const nonMultiSelectConditions = getters.visibleConditions
        .filter(c => !c.isMultiSelect)
        .map(c => {
          const { multiSelectCondition, multiSelectConditionOutcomeIndex } = getNearestMultiSelectConditionWithOutcomeIndexForCondition(c, state.document.conditions)
          const globalOrder = getGlobalOrder(c, multiSelectCondition, multiSelectConditionOutcomeIndex)
          return {
            ...c,
            type: 'condition',
            globalOrder: globalOrder,
            isNested: globalOrder % 1 !== 0, // nested conditions will have float order
          }
        })

      const multiSelectConditionsOutcomes = []
      getters.visibleConditions
        .filter(c => c.isMultiSelect)
        .forEach(c => c.conditionOutcomes.forEach((outcome, index) => {
          multiSelectConditionsOutcomes.push({
            ...outcome,
            title: index === 0 ? c.text : null,
            titleInfo: index === 0 ? c.info : null,
            text: outcome.value,
            defaultValue: false,
            type: 'condition',
            isCheckbox: true,
            globalOrder: c.globalOrder + ((index + 1) / 100),
          })
        }))

      return nonMultiSelectConditions.concat(multiSelectConditionsOutcomes)
    },
    visibleVariableConfigurationObjects (state, getters) {
      return getters.visibleVariables.map(variable => {
        const variableInput = state.variableInputs.find(input => input.id === variable.id)
        let defaultValue = variableInput ? variableInput.value : variable.defaultValue
        if (variable.pattern.startsWith('$/currentDate')) {
          defaultValue = new Date()
        }

        return {
          type: 'variable',
          ...variable,
          isSmartField: variable.isCompanyVatId || variable.isCompanyName || variable.isCompanyAddress || variable.isCompanyPostAndPostCode,
          defaultValue: defaultValue,
        }
      })
    },
    visibleConfigurationObjects (_, getters) {
      const configurationObjects = getters.visibleConditionConfigurationObjects.concat(getters.visibleVariableConfigurationObjects)
      return orderBy(configurationObjects, 'globalOrder')
    },
    progress (_, getters) {
      const numberOfConfigurables = getters.requiredConditions.length + getters.visibleVariables.length
      if (numberOfConfigurables === 0) {
        return 100
      }

      const numberOfSpecifiedConfigurables = getters.requiredChosenOutcomes.length + getters.effectiveVariableInputs.length
      return (numberOfSpecifiedConfigurables / numberOfConfigurables) * 100
    },
    requiredChosenOutcomes (state) {
      const requiredChosenOutcomes = []
      state.chosenConditionOutcomes.forEach(outcomeId => {
        const condition = state.document.conditions.find(condition => {
          const outcomeIds = condition.conditionOutcomes.map(outcome => outcome.id)
          return outcomeIds.includes(outcomeId)
        })

        if (!condition.isMultiSelect) {
          requiredChosenOutcomes.push(outcomeId)
        }
      })

      return uniq(requiredChosenOutcomes)
    },
    isConfigurationValid (state, getters) {
      return getters.requiredConditions.length === getters.requiredChosenOutcomes.length
    },
    isDocumentCompositionFinished (state, getters) {
      return getters.progress === 100
    },
    getVariableDOMIdFn () {
      return (variableId) => {
        return `variable-${variableId}`
      }
    },
    getOutcomeDOMIdFn () {
      return (outcomeId) => {
        return `outcome-${outcomeId}`
      }
    },
    counterPartyName (state, getters) {
      for (let i = 0; i < getters.visibleVariables.length; i++) {
        const variable = getters.visibleVariables[i]
        if (isSmartVariableContainingName(variable)) {
          const variableInput = state.variableInputs.find(input => input.id === variable.id)
          return variableInput?.value
        }
      }
      return null
    },
  },
  actions: {
    async initialize ({ commit, dispatch }, { documentId, isEditingRestricted = false }) {
      commit('SET_IS_EDITING_RESTRICTED', isEditingRestricted)
      commit('SET_IS_LOADING', true, { root: true })

      axios
        .get(`/documents/${documentId}?expand=1`)
        .then((response) => {
          const document = camelKeys(response.data, { recursive: true, recursiveInArray: true })
          commit('SET_DOCUMENT', document)
          dispatch('trackInitEvents')
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju dokumenta, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    async preFillSmartVariables ({ commit, state }, { variable, configurationObject }) {
      if (!variable.isCompanyVatId || !configurationObject.value) {
        return
      }

      try {
        const result = await axios.get(`/company_info/${configurationObject.value}`)
        if (result.data.is_sp) {
          return
        }
        const textSegment = state.document.textSegments.find(segment => segment.id === variable.textSegmentId)
        textSegment.textContent.variables.forEach(variable => {
          if (variable.isCompanyVatId && result.data.is_vat_liable && !configurationObject.value.toLowerCase().startsWith('si-')) {
            const cleanedVatId = configurationObject.value.toLowerCase().replace('si', '').replace('-', '')
            const formattedVatId = `SI-${cleanedVatId}`
            commit('UPDATE_VARIABLE_VALUE', { id: variable.id, pattern: variable.pattern, value: formattedVatId })
          }

          if (variable.isCompanyName) {
            commit('UPDATE_VARIABLE_VALUE', { id: variable.id, pattern: variable.pattern, value: result.data.name })
          } else if (variable.isCompanyAddress) {
            commit('UPDATE_VARIABLE_VALUE', { id: variable.id, pattern: variable.pattern, value: result.data.address })
          } else if (variable.isCompanyPostAndPostCode) {
            commit('UPDATE_VARIABLE_VALUE', { id: variable.id, pattern: variable.pattern, value: result.data.post_and_post_code })
          }
        })
      } catch (e) {
        console.error(e)
        if (e.response?.status === 404) {
          alert(`Podjetje z davčno številko ${configurationObject.value} ni bilo najdeno. Prosimo preverite vnešeno davčno številko.`)
        }
      }
    },
    async setConfigurationObject ({ commit, dispatch, state, getters }, configurationObject) {
      if (configurationObject.configurationObjectType === 'condition') {
        removeChosenConditionOutcomesForConditionOrConditionOutcomeId(configurationObject.id)
        if (configurationObject.value === false) {
          commit('REMOVE_CHOSEN_CONDITION_OUTCOME', configurationObject.id)
        } else if (configurationObject.value === true) {
          commit('ADD_CHOSEN_CONDITION_OUTCOME', configurationObject.id)
        } else {
          commit('ADD_CHOSEN_CONDITION_OUTCOME', configurationObject.value)
        }
      } else if (configurationObject.configurationObjectType === 'variable') {
        const variable = getters.visibleVariables.find(v => v.id === configurationObject.id)
        dispatch('preFillSmartVariables', { variable, configurationObject })
        const pattern = variable.pattern
        commit('SET_VARIABLE_INPUT', {
          id: configurationObject.id,
          value: configurationObject.value,
          pattern: pattern,
        })
      } else {
        throw new Error ('Unexpected configurationObject type!')
      }

      function removeChosenConditionOutcomesForConditionOrConditionOutcomeId (id) {
        let conditionOutcomes = []
        const condition = state.document.conditions.find(condition => condition.id === id)
        if (condition) {
          conditionOutcomes = condition.conditionOutcomes
        } else {
          conditionOutcomes = state.document.conditions
            .flatMap(condition => condition.conditionOutcomes)
            .filter(outcome => outcome.id === id)
        }

        conditionOutcomes.forEach(outcome => {
          commit('REMOVE_CHOSEN_CONDITION_OUTCOME', outcome.id)
          outcome.conditions.forEach(condition => removeChosenConditionOutcomesForConditionOrConditionOutcomeId(condition.id))
        })
      }
    },
    duplicateTextSegment ({ commit, state }, textSegmentId) {
      if (!state.document.textSegments) {
        return
      }

      commit('DUPLICATE_TEXT_SEGMENT', textSegmentId)
    },
    removeDuplicateTextSegment ({ commit, state }, textSegmentId) {
      if (!state.document.textSegments) {
        return
      }

      commit('REMOVE_DUPLICATE_TEXT_SEGMENT', textSegmentId)
    },
    finishDocumentComposition ({ commit, state, getters, rootState, rootGetters, dispatch }) {
      if (!getters.isConfigurationValid) {
        return
      }

      if (!state.document.id) {
        throw new Error('Document has to be present in state to finish document composition!')
      }

      dispatch('trackCompleteEvents')
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      const payload = {
        finalizedDocument: {
          name: state.document.name,
          price: state.document.price,
          htmlContent: getters.interpolatedDocumentHtmlFn(true),
          userId: rootState.user?.id,
          accountId: rootState.account?.id,
          counterpartyName: getters.counterPartyName,
        },
      }

      return axios
        .post('/finalized_documents', snakeKeys(payload, { recursive: true, recursiveInArray: true }))
        .then((response) => {
          commit('SET_IS_DOCUMENT_SAVED', true)
          const finalizedDocument = camelKeys(response.data, { recursive: true, recursiveInArray: true })
          commit('SET_FINALIZED_DOCUMENT_ID', finalizedDocument.id)
          dispatch('saveUnclaimedDocumentIdToLocalStorage')

          // some documents can already be purchased
          if (finalizedDocument.isPurchased) {
            router.push({ name: 'account_recent_finalized_documents_path', params: { accountId: rootGetters.accountSlug } })
          } else {
            router.push({ name: 'finalized_document_payment', params: { id: finalizedDocument.id } })
          }
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri shranjevanju dokumenta, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    async saveUnclaimedDocumentIdToLocalStorage ({ state, rootState }) {
      if (rootState.user || !state.finalizedDocumentId) {
        return
      }

      const unclaimedDocumentIds = JSON.parse(localStorage.getItem('unclaimedDocumentIds'))
      if (unclaimedDocumentIds) {
        localStorage.setItem('unclaimedDocumentIds', JSON.stringify([...unclaimedDocumentIds, state.finalizedDocumentId]))
      } else {
        localStorage.setItem('unclaimedDocumentIds', JSON.stringify([state.finalizedDocumentId]))
      }
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
    trackInitEvents ({ state, dispatch }) {
      const documentItem = {
        id: state.document.id,
        price: state.document.price,
      }

      dispatch('trackEvents', [
        {
          name: 'view_item',
          data: {
            send_to: 'AW-16663620351',
            dynx_pagetype: 'offerdetail',
            dynx_totalvalue: state.document.price,
            dynx_itemid: state.document.id,
            value: state.document.price,
            currency: 'EUR',
            items: [{
              ...documentItem,
              google_business_vertical: 'custom',
            }],
          },
        },
        {
          name: 'view_item',
          data: {
            send_to: 'G-5JHZXZD27X',
            value: state.document.price,
            currency: 'EUR',
            items: [{
              ...documentItem,
              name: documentItem.name,
              item_list_id: 'vzorci',
              item_list_name: 'Vzorci',
              quantity: 1,
              index: 1,
            }],
          },
        },
      ], { root: true })
    },
    trackCompleteEvents ({ state, dispatch }) {
      dispatch('trackEvents', [
        {
          name: 'conversion',
          data: {
            send_to: 'AW-16663620351/P9o5CIiLvsgZEP_V6ok-',
            value: state.document.price,
            currency: 'EUR',
          },
        },
        {
          name: 'add_to_cart',
          data: {
            send_to: 'AW-16663620351',
            dynx_pagetype: 'conversionintent',
            dynx_totalvalue: state.document.price,
            dynx_itemid: state.document.id,
            value: state.document.price,
            currency: 'EUR',
            items: [
              {
                id: state.document.id,
                price: state.document.price,
                google_business_vertical: 'custom',
              },
            ],
          },
        },
        {
          name: 'add_to_cart',
          data: {
            send_to: 'G-5JHZXZD27X',
            value: state.document.price,
            currency: 'EUR',
            items: [
              {
                item_category: 'Vsi vzorci',
                item_list_id: 'vzorci',
                item_list_name: 'Vzorci',
                item_id: state.document.id,
                item_name: state.document.name,
                price: state.document.price,
                quantity: 1,
                index: 1,
              },
            ],
          },
        },
      ], { root: true })
    },
  },
  mutations: {
    SET_DOCUMENT (state, document) {
      state.document = document
    },
    ADD_CHOSEN_CONDITION_OUTCOME (state, conditionOutcomeId) {
      if (!conditionOutcomeId) {
        return
      }

      if (Array.isArray(conditionOutcomeId)) {
        state.chosenConditionOutcomes = state.chosenConditionOutcomes.concat(conditionOutcomeId)
      } else {
        state.chosenConditionOutcomes.push(conditionOutcomeId)
      }
    },
    REMOVE_CHOSEN_CONDITION_OUTCOME (state, conditionOutcomeId) {
      state.chosenConditionOutcomes = state.chosenConditionOutcomes.filter(outcome => outcome !== conditionOutcomeId)
    },
    SET_VARIABLE_INPUT (state, variableInput) {
      state.variableInputs = state.variableInputs.filter(input => input.id !== variableInput.id)
      if (!variableInput.value) {
        return
      }

      if (variableInput.value instanceof Date) {
        variableInput.value = `${variableInput.value.getDate()}.${variableInput.value.getMonth() + 1}.${variableInput.value.getFullYear()}`
      }

      state.variableInputs.push(variableInput)
    },
    SET_FINALIZED_DOCUMENT_ID (state, finalizedDocumentId) {
      state.finalizedDocumentId = finalizedDocumentId
    },
    SET_IS_EDITING_RESTRICTED (state , isEditingRestricted) {
      state.isEditingRestricted = isEditingRestricted
    },
    SET_IS_DOCUMENT_SAVED (state , isDocumentSaved) {
      state.isDocumentSaved = isDocumentSaved
    },
    DUPLICATE_TEXT_SEGMENT (state, textSegmentId) {
      const textSegmentIndex = state.document.textSegments.findIndex(segment => segment.id === textSegmentId)
      if (textSegmentIndex === -1) {
        return
      }

      const textSegment = state.document.textSegments[textSegmentIndex]
      if (!textSegment.isDuplicable) {
        return
      }

      const textSegmentDuplicate = cloneDeep(textSegment)
      textSegmentDuplicate.isDuplicate = true
      textSegmentDuplicate.id = generateRandomId()
      textSegmentDuplicate.originalId = textSegment.id
      textSegmentDuplicate.textContent.variables.forEach(variable => {
        variable.id = generateRandomId()
        const newPattern = `$/pattern-${generateRandomId()}/`
        textSegmentDuplicate.textContent.text = textSegmentDuplicate.textContent.text.replaceAll(variable.pattern, newPattern)
        variable.pattern = newPattern
      })
      state.document.textSegments.splice(textSegmentIndex + 1, 0, textSegmentDuplicate)

      function generateRandomId () {
        return `generated-${Math.floor(Math.random() * 999999)}`
      }
    },
    REMOVE_DUPLICATE_TEXT_SEGMENT (state, textSegmentId) {
      const textSegment = state.document.textSegments.find(segment => segment.id === textSegmentId)
      if (!textSegment || !textSegment.isDuplicate) {
        return
      }

      state.document.textSegments = state.document.textSegments.filter(segment => segment.id !== textSegmentId)
    },
    UPDATE_VARIABLE_VALUE (state, { id, pattern, value }) {
      const variableInputIndex = state.variableInputs.findIndex(variableInput => variableInput.id === id)
      if (variableInputIndex === -1) {
        state.variableInputs.push({ id, pattern, value })
      } else {
        state.variableInputs[variableInputIndex].value = value
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}
