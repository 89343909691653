<template>
  <div class="header" :class="{ 'header--transparent': isHeaderEffectivelyTransparent }" v-if="isMobile || !isLoggedIn">
    <div @click="goToPath('home_path')">
      <img class="header__rubikon-logo" alt="Pigenta Logo" :src="require('app/assets/images/pigenta_logo_full.svg')" />
    </div>

    <div class="header__right-container">
      <template v-if="!isMobile">
        <router-link class="navigation-link" :to="{ name: 'smart_contracts_path' }">Pametni vzorci</router-link>
        <router-link class="navigation-link" :to="{ name: 'blog_path' }">Blog</router-link>
        <a class="navigation-link" @click="openAuthModal()">Prijava</a>
        <v-button
          class="navigation-button"
          :size="navigationButtonSize"
          background-color="primary-blue"
          @click="openB2BPage"
        >
          Podjetja
        </v-button>
      </template>
      <template v-else>
        <div class="sidebar-button" v-if="isLoggedIn" @click="toggleIsFloatingSidebarVisible">
          <v-icon class="sidebar-icon">menu</v-icon>
        </div>
        <div class="sidebar-button" v-else @click="toggleMobileDropdown">
          <v-icon v-if="isMobileDropdownVisible" class="sidebar-icon">close</v-icon>
          <v-icon v-else class="sidebar-icon">menu</v-icon>
        </div>
        <div class="mobile-dropdown" :class="{ 'mobile-dropdown--visible': isMobileDropdownVisible }">
          <div class="mobile-dropdown-link"><a @click="goToPath('smart_contracts_path')">Pametni vzorci</a></div>
          <div class="mobile-dropdown-link"><a @click="goToPath('blog_path')">Blog</a></div>
          <div class="mobile-dropdown-link"><a @click="openAuthModalAndCloseMobileDropdown">Prijava</a></div>
          <v-button background-color="primary-blue" class="mobile-dropdown-button" @click="openB2BPage">Podjetja</v-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import VButton from 'design-system/VButton.vue'
import VIcon from 'design-system/VIcon.vue'

export default {
  components: {
    VIcon,
    VButton,
  },
  data () {
    return {
      isHeaderTransparent: false,
      isMobileDropdownVisible: false,
    }
  },
  watch: {
    $route: {
      handler () {
        this.isHeaderTransparent = !!this.$router.currentRoute.meta?.isHeaderEmbedded
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(['user']),
    navigationButtonSize () {
      return this.isMobile ? 'small' : 'medium'
    },
    isHeaderEffectivelyTransparent () {
      return this.isHeaderTransparent && !this.isMobileDropdownVisible
    },
  },
  methods: {
    ...mapActions(['toggleIsFloatingSidebarVisible', 'openAuthModal']),
    openAuthModalAndCloseMobileDropdown () {
      this.closeMobileDropdown()
      this.openAuthModal()
    },
    goToPath (pathName) {
      this.closeMobileDropdown()
      this.$router.push({ name: pathName }).catch(() => {})
    },
    toggleMobileDropdown () {
      this.isMobileDropdownVisible = !this.isMobileDropdownVisible
    },
    closeMobileDropdown () {
      this.isMobileDropdownVisible = false
    },
    openB2BPage () {
      window.location.href='https://www.pigenta.com'
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';
@import '../common';

.header {
  position: absolute;
  z-index: $z_pop;
  display: flex;
  width: 100%;
  height: $desktop_header_height;
  justify-content: space-between;
  padding: 40px 80px 24px;
  border-bottom: 1px solid $border_color;
  background-color: white;
  color: $primary_color;

  @include respond-to(small) {
    height: $mobile_header_height;
    padding: 24px 16px;
  }

  &--transparent {
    border: none;
    background-color: transparent;
  }

  .sidebar-icon {
    color: $primary_color;
  }
}

.header__right-container {
  display: flex;
}

.header__rubikon-logo {
  width: 120px;
}

.navigation-link.navigation-link {
  padding: 0 16px;
  margin: 0 8px;
  color: $black;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  user-select: none;

  @include respond-to(small) {
    padding: 0 4px;
    margin: 0 4px;
    font-size: 12px;
  }

  &:hover {
    color: $secondary_color;
    text-decoration: none;
  }
}

.navigation-button {
  position: relative;
  top: -5px;
  margin-left: 24px;

  @include respond-to(small) {
    top: -2px;
    margin-left: 8px;
  }
}

.sidebar-button {
  cursor: pointer;
  color: $modrn-blue-dark-90;
}

.mobile-dropdown {
  z-index: $z_heaven;
  display: none;
  position: absolute;
  top: $mobile_header_height;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 12px 16px;

  &--visible {
    display: block;
  }
}

.mobile-dropdown-link {
  padding: 12px 0;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: $black;
    text-decoration: none;
  }
}

.mobile-dropdown-button {
  width: 100%;
  margin: auto;
  margin: 24px 0;
}
</style>
