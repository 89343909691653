<template>
  <div>
    <r-dialog
      v-if="isAuthModalOpen"
      class="auth-modal"
      :can-close="canAuthModalBeClosed"
      :has-blur="authModalBlur"
      @close="onClose"
    >
      <div class="auth__content">
        <router-link class="auth__logo-container" :to="{ name: 'home_path' }">
          <img class="auth__logo" alt="Pigenta Logo" :src="require('app/assets/images/pigenta_logo.svg')" />
        </router-link>
        <login v-if="authModalTab === 'login'" @change-page="setAuthModalTab" />
        <register v-else-if="authModalTab === 'register'" @change-page="setAuthModalTab" />
        <registered v-else-if="authModalTab === 'registered'" @change-page="setAuthModalTab" />
      </div>
    </r-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Login from '../auth/Login.vue'
import Register from '../auth/Register.vue'
import Registered from '../auth/Registered.vue'
import RDialog from './_Dialog.vue'

export default {
  components: {
    Login,
    Register,
    Registered,
    RDialog,
  },
  data () {
    return {
      showLoginPage: true,
    }
  },
  computed: {
    ...mapState(['isAuthModalOpen', 'authModalTab', 'canAuthModalBeClosed', 'authModalBlur']),
  },
  methods: {
    ...mapActions(['setAuthModalTab', 'closeAuthModal']),
    onClose () {
      if (this.canAuthModalBeClosed) {
        this.closeAuthModal()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import 'app/assets/stylesheets/variables';
@import '../common';
@import '../auth/common';

.auth__content {
  max-height: 100%;
  padding: 48px 206px 72px;
  border-radius: 16px;
  margin: auto;
  background-color:$white;
  overflow-y: auto;
  text-align: center;

  @include respond-to(not-large) {
    padding: 48px 48px 72px;
  }

  @include respond-to(small) {
    width: 100%;
    padding: 32px 0 0 0;
  }
}

.auth__logo-container {
  padding: 20px;
  border-radius: 8px;
  margin: auto;
  /* stylelint-disable-next-line */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.auth__logo {
  max-width: 22px;
}

.auth__section {
  padding-bottom: 24px;
}

.md-divider {
  margin-bottom: 24px;
}

.auth-modal {
  /deep/ & .dialog__content {
    max-height: 90%;

    & .dialog__inner-content {
      &::-webkit-scrollbar {
        display: none;
      }

      scrollbar-width: none;
    }

  }
}
</style>
