<template>
  <div class="container">
    <img class="error-image" alt="404 Grafika" :src="require('app/assets/images/404.svg')" />
    <div class="error-text">Ups, ta stran ne obstaja.</div>
    <router-link class="error-link" :to="{ name: 'home_path' }">
      <v-button>Na vstopno stran</v-button>
    </router-link>
  </div>
</template>

<script>
import VButton from 'design-system/VButton.vue'

export default {
  components: { VButton },
}
</script>

<style lang="scss" scoped>
@import './common';
</style>
