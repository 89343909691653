<template>
  <div>
    <v-field :class="getValidationClass('name')">
      <label>Ime</label>
      <v-input v-model="form.name"></v-input>
      <span class="md-error" v-if="!$v.form.name.required">Vpišite ime</span>
    </v-field>

    <v-checkbox v-model="form.isVisible">Viden uporabnikom</v-checkbox>

    <v-checkbox v-model="form.isVisibleToAccounts">Viden organizacijam</v-checkbox>

    <v-field :class="getValidationClass('price')">
      <label>Cena</label>
      <v-input v-model="form.price" type="number"></v-input>
      <span class="md-error" v-if="!$v.form.price.required">Vpišite ceno</span>
    </v-field>

    <div class="text-with-preview">
      <v-field class="text-field">
        <label>Opombe (opcijsko, prikazano uporabniku med izpolnjevanjem)</label>
        <v-textarea v-model="form.generalRemarks"></v-textarea>
      </v-field>

      <div class="text-markdown-preview">
        <div class="text-markdown-preview-title">Predogled</div>
        <div class="text-markdown-preview-content" v-html="generalRemarksMarkdownHtml"></div>
      </div>
    </div>

    <v-field>
      <label>SEO opis</label>
      <v-input v-model="form.seoDescription"></v-input>
    </v-field>

    <v-field :class="getValidationClass('keywords')">
      <label>Ključne besede</label>
      <v-input v-model="form.keywords"></v-input>
      <span class="md-error" v-if="!$v.form.keywords.required">Vpišite ključne besede (ločene z vejico)</span>
    </v-field>

    <v-field>
      <label>SEO ključne besede</label>
      <v-input v-model="form.seoKeywords"></v-input>
    </v-field>

    <v-field>
      <label>Opis</label>
      <v-input v-model="form.description"></v-input>
    </v-field>

    <v-field>
      <label>Relevantne določbe</label>
      <v-input v-model="form.stipulations"></v-input>
    </v-field>

    <v-field>
      <label>Skrbnik</label>
      <v-input v-model="form.owner"></v-input>
    </v-field>

    <v-field>
      <label for="accountIds">Vidno računom</label>
      <v-select v-model="form.accountIds" name="accountIds" id="accountIds" md-dense multiple>
        <v-option v-for="account in accounts" :key="account.id" :value="account.id">{{ account.name }}</v-option>
      </v-select>
    </v-field>
  </div>
</template>

<script>
import snarkdown from 'snarkdown'
import { required } from 'vuelidate/lib/validators'
import ValidationMixin from '../../../shared/ValidationMixin'
import AutocompleteFixMixin from '../../../shared/AutocompleteFixMixin'
import VCheckbox from 'design-system/VCheckbox.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'
import VTextarea from 'design-system/VTextarea.vue'
import VSelect from 'design-system/VSelect.vue'
import VOption from 'design-system/VOption.vue'

export default {
  components: { VCheckbox, VField, VInput, VTextarea, VSelect, VOption },
  data () {
    return {
      form: {
        name: null,
        price: null,
        keywords: null,
        seoKeywords: null,
        description: null,
        seoDescription: null,
        stipulations: null,
        owner: null,
        generalRemarks: null,
        isVisible: false,
        isVisibleToAccounts: false,
        accountIds: [],
      },
    }
  },
  props: {
    document: { type: Object, required: false },
    accounts: { type: Array, required: true },
  },
  mixins: [ValidationMixin, AutocompleteFixMixin],
  created () {
    if (!this.document) {
      return
    }
    this.form.name = this.document.name
    this.form.price = this.document.price
    this.form.keywords = this.document.keywords
    this.form.seoKeywords = this.document.seoKeywords
    this.form.description = this.document.description
    this.form.seoDescription = this.document.seoDescription
    this.form.stipulations = this.document.stipulations
    this.form.owner = this.document.owner
    this.form.generalRemarks = this.document.generalRemarks
    this.form.isVisible = this.document.isVisible ?? this.form.isVisible
    this.form.isVisibleToAccounts = this.document.isVisibleToAccounts ?? this.form.isVisibleToAccounts
    this.form.accountIds = this.document.accounts ? this.document.accounts.map(a => a.id) : []
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.document,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  computed: {
    generalRemarksMarkdownHtml () {
      if (!this.form.generalRemarks) {
        return ''
      }
      return snarkdown(this.form.generalRemarks)
    },
  },
  methods: {
    runAllValidations () {
      this.$v.$touch()
    },
    isValid () {
      return !this.$v.$invalid
    },
  },
  validations: {
    form: {
      name: { required },
      price: { required },
      keywords: { required },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../markdown_preview';
</style>
