import LoginPage from '@/components/auth/LoginPage.vue'
import Home from '@/components/pages/static/Home.vue'
import Blog from '@/components/pages/blog/Blog.vue'
import BlogPost from '@/components/pages/blog/BlogPost.vue'
import SmartContracts from '@/components/pages/SmartContracts.vue'
import SmartContract from '@/components/pages/SmartContract.vue'
import Terms from '@/components/pages/static/Terms.vue'
import Privacy from '@/components/pages/static/Privacy.vue'
import ContractWithdrawal from '@/components/pages/static/ContractWithdrawal.vue'
import Packages from '@/components/pages/static/Packages.vue'

const staticRoutes = [
  { path: '/', component: LoginPage, name: 'root_path', meta: { isHeaderHidden: true, isHiddenIfLoggedIn: true, title: 'Prijava', description: 'Prijavite se v aplikacijo Pigenta in dostopajte do svojih pogodb ter pravnih dokumentov.' } },
  { path: '/dokumenti', component: Home, name: 'home_path', meta: { isHeaderEmbedded: true, showGuaranteeHeader: true, showNewsletterPopup: true, isHiddenIfLoggedIn: true, title: 'Pigenta | Dostopajte do podatkov v vseh vaših pogodbah in dokumentih.', description: 'Pigenta omogoča hiter dostop do podatkov v vseh vaših pravnih dokumentih. Učinkovito sklenite posle, obvladajte dogovore in pospešite rast prihodkov.' } },
  { path: '/pogoji', component: Terms, name: 'terms_path', meta: { title: 'Pogoji', description: 'Splošni pogoji poslovanja spletne trgovine Pigenta opisujejo postopek nakupa, določajo vaše pravice in obveznosti. Preberite več o tem, kako uspešno nakupovati.' } },
  { path: '/zasebnost', component: Privacy, name: 'privacy_path', meta: { title: 'Zasebnost', description: 'Preberite, kako Pigenta varuje vašo zasebnost in zagotavlja varno obdelavo osebnih podatkov.' } },
  { path: '/odstop-od-pogodbe', component: ContractWithdrawal, name: 'contract_withdrawal_path', meta: { title: 'Odstop od pogodbe', description: 'Spoznajte obveznosti in pogoje za odstop od pogodbe, če kupljenega pravnega dokumenta ne želite.' } },
].map(route => {
  route.meta.showFooter = true
  route.meta.isStaticRoute = true

  return route
})

const otherRoutes = [
  { path: '/blog', component: Blog, name: 'blog_path', meta: { showNewsletterPopup: true, showFooterIfNotLoggedIn: true, title: 'Novice, nasveti in vpogledi na področju upravljanja pogodb Blog', description: 'Pigenta blog je vaš vir za vse o upravljanju pogodb in pravnih dokumentov. Odkrijte najnovejše novice, praktične nasvete in dragocena spoznanja.' } },
  { path: '/blog/:id', component: BlogPost, name: 'blog_post_path', meta: { isHeaderEmbedded: true, showNewsletterPopup: true, showFooterIfNotLoggedIn: true }, props: true },
  { path: '/vzorci', component: SmartContracts, name: 'smart_contracts_path', meta: { title: 'Vzorci pogodb in pravnih dokumentov', description: 'Raznolika izbira vzorcev pogodb, pravilnikov, aktov in drugih pravnih dokumentov. Enostavno in hitro urejanje z zagotovljeno brezhibnostjo in pravno skladnostjo.' } },
  { path: '/sestavi-dokument/:id', component: SmartContract, name: 'compose_document_path', meta: { }, props: true },
  { path: '/paketi', component: Packages, name: 'packages_path', meta: { title: 'Paketi' } },
]

export default staticRoutes.concat(otherRoutes)
