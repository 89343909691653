<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  created () {
    this.openAuthModal({
      allowModalClose: false,
      modalBlur: false,
    })
  },
  methods: {
    ...mapActions(['openAuthModal']),
  },
}
</script>
